import { styled } from "./theme";
import type * as Stitches from "@stitches/react";
import { FC } from "react";

const Svg = styled("svg");

type StarProps = Stitches.ComponentProps<typeof Svg>;

export const Star: FC<StarProps> = (props) => {
  return (
    <Svg width="26" height="26" viewBox="-12 -15 52 52" {...props}>
      <path
        d="
        M 14.000 28.000
        L 29.282 35.034
        L 27.315 18.326
        L 38.727 5.966
        L 22.229 2.674
        L 14.000 -12.000
        L 5.771 2.674
        L -10.727 5.966
        L 0.685 18.326
        L -1.282 35.034
        L 14.000 28.000
        "
        stroke="#FFF"
        strokeWidth="2"
        fill="none"
      />
    </Svg>
  );
};
