import { render } from "react-dom";
import { Card } from "./components/card";
import { useGlobalStyles } from "./styled/reset";
import { themeGlobal, themes } from "./styled/theme";
import { useBoardType } from "./hooks/board.hook";
import { useEffect } from "react";
const App = () => {
  useGlobalStyles();
  themeGlobal();
  const { boardType } = useBoardType();

  // Apply theme changes
  useEffect(() => {
    document.body.className = themes[boardType];
  }, [boardType]);

  return <Card />;
};

render(<App />, document.getElementById("root"));
