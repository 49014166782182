import { Color } from "./base-data";

const colors: Color[][] = [
  ["R", "G", "G", "Y", "Y", "Y", "Y", "O", "R", "R", "G", "G", "G", "B", "Y"],
  ["R", "G", "R", "R", "R", "Y", "Y", "O", "R", "R", "G", "G", "B", "B", "B"],
  ["B", "R", "R", "B", "B", "G", "G", "G", "G", "G", "R", "B", "O", "B", "R"],
  ["O", "O", "O", "O", "B", "B", "B", "B", "G", "Y", "Y", "B", "R", "R", "R"],
  ["Y", "B", "B", "O", "O", "R", "R", "R", "B", "B", "B", "R", "R", "O", "O"],
  ["Y", "Y", "B", "G", "G", "O", "O", "Y", "Y", "O", "B", "Y", "Y", "Y", "O"],
  ["G", "Y", "Y", "G", "G", "O", "O", "Y", "O", "O", "O", "O", "Y", "G", "G"],
];

const stars: boolean[][] = [
  [
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
  ],
  [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  [
    false,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    true,
    false,
    true,
    true,
    false,
  ],
  [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
  ],
  [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
  ],
  [
    false,
    false,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  [
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
];

export const board = {
  colors,
  stars,
};
