import { styled } from "./theme";
import type { ComponentProps } from "@stitches/react";
import type { FC } from "react";

const StyledSvgText = styled("text");

const SvgText: FC<ComponentProps<typeof StyledSvgText>> = (props) => {
  return (
    <StyledSvgText
      y="22"
      stroke="white"
      strokeWidth="2px"
      fontWeight="bold"
      paintOrder="stroke fill"
      strokeLinejoin="round"
      {...props}
    >
      {props.children}
    </StyledSvgText>
  );
};

export const BonusText = () => {
  return (
    <svg viewBox="-4 0 60 30">
      <SvgText css={{ fill: "$greenDark" }} x="2">
        B
      </SvgText>
      <SvgText css={{ fill: "$yellowDark" }} x="12">
        O
      </SvgText>
      <SvgText css={{ fill: "$blueDark" }} x="24">
        N
      </SvgText>
      <SvgText css={{ fill: "$redDark" }} x="35">
        U
      </SvgText>
      <SvgText css={{ fill: "$orangeDark" }} x="46">
        S
      </SvgText>
    </svg>
  );
};
