import { ComponentProps } from "@stitches/react";
import type { FC } from "react";
import { data } from "../data/data";
import {
  ScoreType,
  useBoardState,
  useBoardType,
  useCalculateScore,
} from "../hooks/board.hook";
import { BonusText } from "../styled/bonus-text";
import { Box } from "../styled/box";
import { Button } from "../styled/button";
import { Cross } from "../styled/cross";
import { ExclamationMark } from "../styled/exclamation-mark";
import { Star } from "../styled/star";
import { styled } from "../styled/theme";
import { Tile } from "../styled/tile";

const Text = styled("span");
const Select = styled("select");
const CHECK = <Text css={{ color: "Black" }}>✓</Text>;

const Columns: FC = () => {
  return (
    <Box css={{ display: "flex", marginBottom: "0.5rem" }}>
      {data.columns.map((column, i) => (
        <Tile key={column} highlight={i === 7} variant="header">
          {column}
        </Tile>
      ))}
    </Box>
  );
};

const Scores: FC<{
  marks: ScoreType[];
  setMark: (x: number, y: number) => void;
}> = ({ marks, setMark }) => {
  return (
    <Box
      css={{ display: "flex", flexDirection: "column", marginTop: "0.5rem" }}
    >
      {data.scores.map((row, y) => (
        <Box key={y}>
          {row.map((score, x) => (
            <Tile
              key={`${y}-${x}`}
              highlight={x === 7}
              variant="score"
              onClick={() => setMark(x, y)}
            >
              {score}
              {y === 0 &&
                (marks[x] === ScoreType.FIRST_BLOCKED ||
                  marks[x] === ScoreType.CLOSED) && (
                  <Cross css={{ position: "absolute" }} />
                )}
              {((y === 0 && marks[x] === ScoreType.CLOSED_FIRST) ||
                (y === 1 && marks[x] === ScoreType.CLOSED)) &&
                CHECK}
            </Tile>
          ))}
        </Box>
      ))}
    </Box>
  );
};

const Board: FC<{
  marks: boolean[][];
  setMark: (x: number, y: number) => void;
}> = ({ marks, setMark }) => {
  const { boardType } = useBoardType();
  return (
    <Box>
      {data.boards[boardType].colors.map((row, y) => (
        <Box key={y} css={{ display: "flex" }}>
          {row.map((color, x) => (
            <Tile
              key={`${y}-${x}`}
              color={color as any}
              highlight={x === 7}
              variant="board"
              onClick={() => setMark(x, y)}
              inlay={
                !data.boards[boardType].stars[y]?.[x] ? "circle" : undefined
              }
            >
              {data.boards[boardType].stars[y]?.[x] && (
                <Star css={{ position: "absolute" }} />
              )}
              {marks[y][x] && <Cross />}
            </Tile>
          ))}
        </Box>
      ))}
    </Box>
  );
};

const ColorBlockBox = styled(Tile, {});

const ScoreTypeDisplay: FC<{ marks: ScoreType[]; x: number; y: number }> = ({
  x,
  y,
  marks,
}) => {
  return (
    <>
      {y === 0 &&
        (marks[x] === ScoreType.FIRST_BLOCKED ||
          marks[x] === ScoreType.CLOSED) && (
          <Cross css={{ position: "absolute" }} />
        )}
      {y === 0 && marks[x] === ScoreType.CLOSED_FIRST && CHECK}
      {y === 1 && marks[x] === ScoreType.CLOSED && CHECK}
    </>
  );
};

const ColorBlock: FC<{
  marks: ScoreType[];
  setMark: (x: number, y: number) => void;
}> = ({ marks, setMark }) => {
  return (
    <Box css={{ display: "flex", flexDirection: "column" }}>
      {data.colors.map((color, i) => (
        <Box key={i} css={{ display: "flex", justifyContent: "flex-end" }}>
          <ColorBlockBox
            color={color}
            variant="color"
            onClick={() => setMark(i, 0)}
          >
            5<ScoreTypeDisplay marks={marks} x={i} y={0} />
          </ColorBlockBox>
          <ColorBlockBox
            color={color}
            variant="color"
            onClick={() => setMark(i, 1)}
          >
            3<ScoreTypeDisplay marks={marks} x={i} y={1} />
          </ColorBlockBox>
        </Box>
      ))}
    </Box>
  );
};

const ScoreLabel = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  margin: "4px",
  height: "30px",
  color: "White",
});

const ScoreBlock = styled("div", {
  display: "flex",
  alignItems: "center",
  margin: "4px 1px",
  height: "30px",
  width: "65px",
  backgroundColor: "#FFF",
  padding: "4px 8px",
  borderRadius: "4px",
});

const Result: FC<{
  scoreMarks: ScoreType[];
  boardMarks: boolean[][];
  jokerMarks: boolean[];
  colorMarks: ScoreType[];
}> = ({ scoreMarks, boardMarks, jokerMarks, colorMarks }) => {
  const score = useCalculateScore({
    scoreMarks,
    boardMarks,
    jokerMarks,
    colorMarks,
  });

  return (
    <Box css={{ marginLeft: "0.5rem" }}>
      <Box css={{ display: "flex" }}>
        <Box>
          <ScoreLabel>
            <BonusText />
          </ScoreLabel>
          <ScoreLabel>A-O</ScoreLabel>
          <ScoreLabel>
            <Box
              css={{
                marginRight: "0.2rem",
                backgroundColor: "#fff",
                width: "26px",
                height: "26px",
                borderRadius: "26px",
                svg: {
                  width: "26px",
                  height: "26px",
                },
              }}
            >
              <ExclamationMark />
            </Box>{" "}
            (+1)
          </ScoreLabel>
          <ScoreLabel>
            <Star
              viewBox="-20 -20 70 70"
              css={{
                marginRight: "0.2rem",
                path: { fill: "#fff" },
              }}
            />
            (-2)
          </ScoreLabel>
        </Box>
        <Box>
          <ScoreBlock>{score.colors}</ScoreBlock>
          <ScoreBlock>{score.board}</ScoreBlock>
          <ScoreBlock>{score.jokers}</ScoreBlock>
          <ScoreBlock>{score.stars}</ScoreBlock>
        </Box>
      </Box>
      <Box
        css={{
          display: "flex",
          justifyContent: "flex-end",
          borderTop: "1px dashed #FFF",
        }}
      >
        <Box>
          <ScoreLabel>Total</ScoreLabel>
        </Box>
        <Box>
          <ScoreBlock>{score.total}</ScoreBlock>
        </Box>
      </Box>
    </Box>
  );
};

const Jokers: FC<{ marks: boolean[]; setMark: (index: number) => void }> = ({
  marks,
  setMark,
}) => {
  return (
    <Box
      css={{
        display: "inline-flex",
        backgroundColor: "White",
        padding: "4px",
        marginTop: "0.5rem",
        borderRadius: "4px",
      }}
    >
      <Box
        css={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "0.5rem",
        }}
      >
        ? / x =
      </Box>
      <Box
        css={{
          display: "flex",
        }}
      >
        {marks.map((marked, i) => (
          <Box
            key={i}
            css={{
              display: "inline-flex",
              width: "32px",
              height: "32px",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #000",
              borderRadius: "999999px",
              marginRight: "0.5rem",
              cursor: "pointer",
              overflow: "hidden",
            }}
            onClick={() => setMark(i)}
          >
            <ExclamationMark />
            {marked && <Cross css={{ position: "absolute" }} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Container = styled(Box, {
  position: "relative",
  padding: "1rem",
  backgroundColor: "$background",
});

export const Card: FC<ComponentProps<typeof Container>> = (props) => {
  const {
    boardMarks,
    scoreMarks,
    jokerMarks,
    colorMarks,
    onSetBoardMark,
    onSetColorMark,
    onSetJokerMark,
    onSetScoreMark,
    reset,
  } = useBoardState();
  const { boardType, setBoardType } = useBoardType();

  const onReset = () => {
    if (confirm("Reset will clean the board")) {
      reset();
    }
  };

  const onBoardType = (ev: any) => {
    reset();
    setBoardType(ev.target.value);
  };

  return (
    <Container {...props}>
      <Box>
        <Box css={{ display: "flex" }}>
          <Box>
            <Columns />
            <Board marks={boardMarks} setMark={onSetBoardMark} />
            <Scores marks={scoreMarks} setMark={onSetScoreMark} />
          </Box>
          <Box>
            <ColorBlock marks={colorMarks} setMark={onSetColorMark} />
            <Result
              scoreMarks={scoreMarks}
              boardMarks={boardMarks}
              jokerMarks={jokerMarks}
              colorMarks={colorMarks}
            />
          </Box>
        </Box>
        <Jokers marks={jokerMarks} setMark={onSetJokerMark} />
        <Button css={{ marginLeft: "1rem" }} onClick={onReset}>
          Reset
        </Button>
        <Select
          css={{ marginLeft: "1rem", padding: "0.2rem" }}
          onChange={onBoardType}
          value={boardType}
        >
          {Object.keys(data.boards).map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
      </Box>
    </Container>
  );
};
