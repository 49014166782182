import { styled } from "./theme";
import type { ComponentProps } from "@stitches/react";
import type { FC } from "react";

const Svg = styled("svg");

type StarProps = ComponentProps<typeof Svg>;

export const ExclamationMark: FC<StarProps> = (props) => {
  return (
    <Svg
      width="280.000000pt"
      height="274.000000pt"
      viewBox="0 0 280.000000 274.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(10.000000,274.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1205 2111 c-84 -10 -89 -16 -97 -123 -16 -190 -29 -407 -34 -544 -7
-185 -2 -199 88 -206 125 -11 207 15 234 73 6 13 30 62 52 109 23 47 46 105
52 130 6 25 24 70 39 100 16 30 31 73 35 95 4 22 20 67 36 100 18 39 32 91 40
149 6 50 13 93 16 97 13 22 -47 29 -223 27 -103 -1 -210 -4 -238 -7z"
        />
        <path
          d="M1145 959 c-55 -23 -64 -34 -82 -99 -26 -96 -11 -149 58 -209 50 -43
54 -44 115 -43 61 0 66 2 118 49 93 82 98 168 14 250 -41 40 -111 73 -153 72
-11 0 -42 -9 -70 -20z"
        />
      </g>
    </Svg>
  );
};
