import { styled, themes } from "./theme";

export const Tile = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  borderRadius: "4px",
  margin: "1px",
  userSelect: "none",
  fontFamily: "$boogaloo",
  "> span": {
    zIndex: 1,
    height: "1em",
  },
  boxShadow: "#000 0px 0px 0 2px",
  [`.${themes.dark} &`]: {
    boxShadow: "none",
  },
  variants: {
    inlay: {
      circle: {
        "&::before": {
          position: "absolute",
          content: "",
          display: "inline-block",
          width: "26px",
          height: "26px",
          borderRadius: "26px",
        },
      },
    },
    variant: {
      header: {
        fontSize: "22px",
        lineHeight: "22px",
      },
      board: {
        cursor: "pointer",
        fontSize: "20px",
        lineHeight: "20px",
      },
      score: {
        cursor: "pointer",
        fontSize: "22px",
        lineHeight: "22px",
      },
      color: {
        cursor: "pointer",
        fontSize: "20px",
        lineHeight: "20px",
      },
    },
    highlight: { true: {} },
    color: {
      default: {
        backgroundColor: "White",
      },
      G: {
        backgroundColor: "$greenDark",
        "&::before": {
          backgroundColor: "$green",
        },
        path: { fill: "$green" },
      },
      Y: {
        backgroundColor: "$yellowDark",
        "&::before": {
          backgroundColor: "$yellow",
        },
        path: { fill: "$yellow" },
      },
      O: {
        backgroundColor: "$orangeDark",
        "&::before": {
          backgroundColor: "$orange",
        },
        path: { fill: "$orange" },
      },
      R: {
        backgroundColor: "$redDark",
        "&::before": {
          backgroundColor: "$red",
        },
        path: { fill: "$red" },
      },
      B: {
        backgroundColor: "$blueDark",
        "&::before": {
          backgroundColor: "$blue",
        },
        path: { fill: "$blue" },
      },
    },
  },
  defaultVariants: {
    color: "default",
  },
  compoundVariants: [
    {
      variant: "color",
      color: "G",
      css: {
        backgroundColor: "$green",
        color: "$greenDarkest",
      },
    },
    {
      variant: "color",
      color: "Y",
      css: {
        backgroundColor: "$yellow",
        color: "$yellowDarkest",
      },
    },
    {
      variant: "color",
      color: "B",
      css: {
        backgroundColor: "$blue",
        color: "$blueDarkest",
      },
    },
    {
      variant: "color",
      color: "R",
      css: {
        backgroundColor: "$red",
        color: "$redDarkest",
      },
    },
    {
      variant: "color",
      color: "O",
      css: {
        backgroundColor: "$orange",
        color: "$orangeDarkest",
      },
    },
    {
      highlight: true,
      variant: "board",
      css: {
        boxShadow: "#FFF 0px 0px 0 2px !important",
        zIndex: 1,
      },
    },
    {
      highlight: true,
      variant: "header",
      css: {
        color: "$red",
      },
    },
    {
      highlight: true,
      variant: "score",
      css: {
        color: "$red",
      },
    },
  ],
});
