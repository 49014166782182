import { createStitches } from "@stitches/react";

export const theme = createStitches({
  media: {
    bph1: "(min-height: 430px)",
  },
  theme: {
    colors: {
      green100: "#a2d863",
      green300: "#7bca29",
      green500: "#66b330",
      orange100: "#f49846",
      orange300: "#e97222",
      orange500: "#d96c17",
      yellow100: "#fed345",
      yellow300: "#fac70b",
      yellow500: "#f1ac18",
      blue100: "#92dcf8",
      blue300: "#68cdf0",
      blue500: "#60bed7",
      red100: "#f14e90",
      red300: "#eb0669",
      red500: "#d3095c",

      green: "$green100",
      greenDark: "$green300",
      greenDarkest: "$green500",
      greenCardBackground: "#7ab928",

      orange: "$orange100",
      orangeDark: "$orange300",
      orangeDarkest: "$orange500",
      orangeCardBackground: "#e56f4e",

      yellow: "$yellow100",
      yellowDark: "$yellow300",
      yellowDarkest: "$yellow500",
      yellowCardBackground: "#f2b449",

      blue: "$blue100",
      blueDark: "$blue300",
      blueDarkest: "$blue500",
      blueCardBackground: "#0ba5ef",

      red: "$red100",
      redDark: "$red300",
      redDarkest: "$red500",
      redCardBackground: "#e74b70",

      darkCardBackground: "#033d47",
      purpleCardBackground: "#54348a",

      // Overwritten by theme
      background: "$darkCardBackground",
    },
    fonts: {
      boogaloo: "bongaloo, sans-serif",
    },
  },
});

export const themeGlobal = theme.globalCss({
  html: {
    height: "100%",
  },
  body: {
    display: "flex",
    backgroundColor: "$background",
    // Make sure you can zoom out and see the entire body
    minWidth: "935px",
    "@bph1": {
      minWidth: "auto",
    },
  },
});

export const styled = theme.styled;

const darkTheme = theme.createTheme({
  colors: {
    background: "$darkCardBackground",
  },
});

const redTheme = theme.createTheme({
  colors: {
    background: "$redCardBackground",
  },
});

const blueTheme = theme.createTheme({
  colors: {
    background: "$blueCardBackground",
  },
});

const greenTheme = theme.createTheme({
  colors: {
    background: "$greenCardBackground",
  },
});

const orangeTheme = theme.createTheme({
  colors: {
    background: "$orangeCardBackground",
  },
});

const yellowTheme = theme.createTheme({
  colors: {
    background: "$yellowCardBackground",
  },
});

const purpleTheme = theme.createTheme({
  colors: {
    background: "$purpleCardBackground",
  },
});

export const themes = {
  dark: darkTheme,
  red: redTheme,
  blue: blueTheme,
  green: greenTheme,
  orange: orangeTheme,
  yellow: yellowTheme,
  purple: purpleTheme,
};
