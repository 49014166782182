import { useMemo } from "react";
import { data } from "../data/data";
import useLocalStorage from "./localstorage.hook";

export enum ScoreType {
  AVAILABLE, // nothing happened yet
  FIRST_BLOCKED, // top score blocked
  CLOSED_FIRST, // have top score
  CLOSED, // save secondairy score
}

type BoardMarksType = boolean[][];
type ScoreMarksType = ScoreType[];
type JokerMarksType = boolean[];
type ColorMarksType = ScoreType[];

export const useBoardType = () => {
  const [boardType, setBoardType] = useLocalStorage<
    keyof typeof data["boards"]
  >("boardType", "dark");
  return { boardType, setBoardType };
};

export const useBoardState = () => {
  const [boardMarks, setBoardMarks] = useLocalStorage<BoardMarksType>(
    "boardMarks",
    Array(data.columns.length).fill([])
  );
  const [scoreMarks, setScoreMarks] = useLocalStorage<ScoreMarksType>(
    "scoreMarks",
    Array(data.columns.length).fill(ScoreType.AVAILABLE)
  );
  const [jokerMarks, setJokerMarks] = useLocalStorage<JokerMarksType>(
    "jokerMarks",
    Array(data.jokers).fill(false)
  );
  const [colorMarks, setColorMarks] = useLocalStorage<ColorMarksType>(
    "colorMarks",
    Array(data.colors.length).fill(ScoreType.AVAILABLE)
  );

  const handleScoreType = (x: number, y: number, data: ScoreType[]) => {
    if (data[x] === ScoreType.AVAILABLE) {
      if (y === 0) data[x] = ScoreType.CLOSED_FIRST;
      else if (y === 1) data[x] = ScoreType.CLOSED;
    } else if (data[x] === ScoreType.CLOSED_FIRST) {
      if (y === 0) data[x] = ScoreType.FIRST_BLOCKED;
      else if (y === 1) data[x] = ScoreType.CLOSED;
    } else if (data[x] === ScoreType.FIRST_BLOCKED) {
      if (y === 0) data[x] = ScoreType.AVAILABLE;
      else if (y === 1) data[x] = ScoreType.CLOSED;
    } else if (data[x] === ScoreType.CLOSED) {
      if (y === 0) data[x] = ScoreType.CLOSED_FIRST;
      else if (y === 1) data[x] = ScoreType.AVAILABLE;
    }
    return data;
  };

  const onSetBoardMark = (x: number, y: number) => {
    const newBoardMarks = JSON.parse(JSON.stringify(boardMarks));
    newBoardMarks[y][x] = !newBoardMarks[y][x];
    setBoardMarks(newBoardMarks);
  };

  const onSetScoreMark = (x: number, y: number) => {
    setScoreMarks(handleScoreType(x, y, [...scoreMarks]));
  };

  const onSetJokerMark = (index: number) => {
    const marks = [...jokerMarks];
    marks[index] = !marks[index];
    setJokerMarks(marks);
  };

  const onSetColorMark = (x: number, y: number) => {
    setColorMarks(handleScoreType(x, y, [...colorMarks]));
  };

  const reset = () => {
    setBoardMarks(Array(data.columns.length).fill([]));
    setScoreMarks(Array(data.columns.length).fill(ScoreType.AVAILABLE));
    setJokerMarks(Array(data.jokers).fill(false));
    setColorMarks(Array(data.colors.length).fill(ScoreType.AVAILABLE));
  };

  return {
    boardMarks,
    scoreMarks,
    jokerMarks,
    colorMarks,
    onSetBoardMark,
    onSetScoreMark,
    onSetJokerMark,
    onSetColorMark,
    reset,
  };
};

export const useCalculateScore = ({
  boardMarks,
  scoreMarks,
  jokerMarks,
  colorMarks,
}: {
  boardMarks: BoardMarksType;
  scoreMarks: ScoreMarksType;
  jokerMarks: JokerMarksType;
  colorMarks: ColorMarksType;
}) => {
  const { boardType } = useBoardType();
  return useMemo(() => {
    const scoreSum = scoreMarks.reduce((total, mark, x) => {
      if (mark === ScoreType.CLOSED_FIRST) {
        return total + data.scores[0][x];
      }
      if (mark === ScoreType.CLOSED) {
        return total + data.scores[1][x];
      }
      return total;
    }, 0);
    const starSum = data.boards[boardType].stars.reduce((total, stars, y) => {
      return (
        total +
        stars.reduce((rowTotal, isStar, x) => {
          if (isStar && !boardMarks[y][x]) {
            return rowTotal - 2;
          }
          return rowTotal;
        }, 0)
      );
    }, 0);
    const jokerSum = jokerMarks.reduce((total, marked) => {
      return total - (marked ? 1 : 0);
    }, data.jokers);
    const colorSum = colorMarks.reduce((total, mark, x) => {
      if (mark === ScoreType.CLOSED_FIRST) {
        return total + 5;
      }
      if (mark === ScoreType.CLOSED) {
        return total + 3;
      }
      return total;
    }, 0);
    return {
      board: scoreSum,
      stars: starSum,
      jokers: jokerSum,
      colors: colorSum,
      total: scoreSum + starSum + jokerSum + colorSum,
    };
  }, [scoreMarks, boardMarks, jokerMarks, colorMarks]);
};
