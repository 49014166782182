import { colors, columns, scores } from "./base-data";
import { board as darkBoard } from "./dark-board";
import { board as redBoard } from "./red-board";
import { board as blueBoard } from "./blue-board";
import { board as greenBoard } from "./green-board";
import { board as orangeBoard } from "./orange-board";
import { board as yellowBoard } from "./yellow-board";
import { board as purpleBoard } from "./purple-board";

const jokers = 8;

export const data = {
  columns,
  colors,
  boards: {
    dark: darkBoard,
    red: redBoard,
    blue: blueBoard,
    green: greenBoard,
    orange: orangeBoard,
    yellow: yellowBoard,
    purple: purpleBoard,
  },
  scores,
  jokers,
};
