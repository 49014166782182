import { styled } from "./theme";

export const Button = styled("button", {
  border: "1px solid #cb2424",
  backgroundColor: "#fff",
  color: "#cf1313",
  fontSize: "14px",
  padding: "0.2rem 0.5rem",
  cursor: "pointer",
  borderRadius: "4px",
});
