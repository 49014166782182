import { styled } from "./theme";

export const Cross = styled("div", {
  width: "26px",
  height: "26px",
  position: "relative",
  "&:before, &:after": {
    position: "absolute",
    left: `12px`,
    content: " ",
    height: "26px",
    width: "2px",
    backgroundColor: "#000",
  },
  "&:before": {
    transform: "rotate(45deg)",
  },
  "&:after": {
    transform: "rotate(-45deg)",
  },
});
